import React from "react";
import { useInView } from "react-intersection-observer";
import "../css/animations.css";

const FadeInSection = ({ children }: any) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className={`fade-in-section ${inView ? "is-visible" : ""}`} ref={ref}>
      {children}
    </div>
  );
};

export default FadeInSection;

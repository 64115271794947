import React, { useState, useRef, useEffect } from "react";
import Pop1 from "../Components/Pop1";
import Pop2 from "../Components/Pop2";
import { useMediaQuery } from "react-responsive";
import imgb1 from "../Image/abImg1.svg";
import imgb2 from "../Image/abImg2.svg";
import imgb3 from "../Image/abImg3.svg";
import imgb4 from "../Image/abImg4.svg";
import aImg from "../Image/analyticImg.png";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import FadeInSection from "../Components/FadInSection";
export default function TermsOfUse() {
  const [showAbt, setShowAbt] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 1250, minWidth: 767 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [colab, showColab] = useState(false);
  const [podcast, showPodcast] = useState(false);
  const sliderUrl3 = [imgb1, imgb2, imgb3, imgb4];
  const AppF = useRef(null);
  const BrandV = useRef(null);
  const Faq = useRef(null);
  const scrollToSection = (elementRef: any) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="body">
        {showAbt && (
          <Pop1
            sliderImageUrl={sliderUrl3}
            closePop={() => setShowAbt(false)}
            bText={
              <p>
                At StableBlocks, we believe that investing should be more than
                just about returns —
                <span>it’s about building the lifestyle you want.</span>
                {isMobile ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : isTab ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}{" "}
                We connect you with promising projects, talents, unique brand
                vouchers, and tools to help you make informed decisions. Whether
                you're investing for returns or reinvesting in your own
                experiences, StableBlocks is here for the entire journey.
              </p>
            }
            img1={imgb1}
            img2={imgb2}
            img3={imgb3}
            img4={imgb4}
          />
        )}
        {colab && (
          <>
            <Pop2
              text1="App feature"
              text2="Collaborate With Us"
              closePop={() => showColab(false)}
              bText={<p>Text Text</p>}
              img1={aImg}
            />
          </>
        )}
        {podcast && (
          <>
            <Pop2
              text1="App feature"
              text2="Our Podcast"
              closePop={() => showPodcast(false)}
              bText={<p>Text Text</p>}
              img1={aImg}
            />
          </>
        )}
        {/* <NavBar
          AppF={() => scrollToSection(AppF)}
          BrandV={() => scrollToSection(BrandV)}
          Faq={() => scrollToSection(Faq)}
        />
        <div className="topper"></div> */}
        <div className="tCont">
          <div className="tItemHead">
            <h4 className="tH">Terms of Use</h4>
            <p>Legal information and notices</p>
          </div>
          <div className="tItemBody">
            <FadeInSection>
              <div className="item">
                <h4>Definitions</h4>
                <p>
                  For the purpose of these Terms of Service ("Terms"), the
                  following definitions apply: - *"Company," "we," "our," or
                  "us"* refers to Trendx.
                  <br /> - *"Platform"* refers to the Trendx website and its
                  tokenization services.
                  <br />- *"User," "you," or "your"* refers to any individual
                  accessing or using our Platform. - *"Account"* refers to the
                  User's registered account on the Platform.
                  <br />- *"Token"* refers to the digital representation of a
                  real-world asset.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Changes</h4>
                <p>
                  Trendx reserves the right to modify these Terms at any time.
                  We will notify you of any changes by posting the updated Terms
                  on our Platform. Continued use of the Platform after such
                  changes constitutes acceptance of the new Terms.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Digital Platform Terms</h4>
                <p>
                  Our Platform provides tokenization services allowing users to
                  tokenize real-world assets. By using our Platform, you agree
                  to comply with these Terms and all applicable laws and
                  regulations.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Network Device Requirements</h4>
                <p>
                  To access our Platform, you must have a compatible device,
                  internet access, and certain necessary software. You are
                  responsible for any fees associated with your access.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Eligibility and Registration</h4>
                <p>
                  You must be at least 18 years old and capable of forming a
                  binding contract in your jurisdiction to use our Platform. You
                  agree to provide accurate and complete information during the
                  registration process and to keep your information updated.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Intellectual Property</h4>
                <p>
                  All content on the Platform, including text, graphics, logos,
                  and software, is the property of Trendx or its licensors and
                  is protected by intellectual property laws. You may not use
                  any content without our prior written permission.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Account</h4>
                <p>
                  You are responsible for maintaining the confidentiality of
                  your account information, including your password, and for all
                  activities that occur under your account. Notify us
                  immediately of any unauthorized use of your account.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Eligibility and Registration</h4>
                <p>
                  Our Platform may allow you to link or connect to third-party
                  accounts. You agree to comply with the terms and conditions of
                  any third-party accounts you link to our Platform.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>User Access Obligations</h4>
                <p>
                  You agree to use our Platform in compliance with these Terms
                  and applicable laws. You are responsible for all actions taken
                  under your account.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Provision of Material and Information</h4>
                <p>
                  You are responsible for providing accurate and complete
                  information when using our Platform. You grant Trendx the
                  right to use, reproduce, and distribute any material or
                  information you provide in connection with the Platform.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Prohibited Uses</h4>
                <p>
                  You agree not to use our Platform for any unlawful purposes,
                  including but not limited to: - Engaging in market
                  manipulation or fraudulent activity.
                  <br /> - Violating any applicable laws or regulations.
                  <br /> - Distributing viruses or harmful software.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Authorized Individuals</h4>
                <p>
                  You may authorize individuals to act on your behalf using your
                  account. You are responsible for the actions of any authorized
                  individuals and must ensure they comply with these Terms.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Security</h4>
                <p>
                  We implement security measures to protect your information.
                  However, we cannot guarantee absolute security. You are
                  responsible for maintaining the security of your account
                  credentials.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Service and Other Notifications</h4>
                <p>
                  We may send you service-related notices, including updates,
                  security alerts, and administrative messages. You agree to
                  receive these communications electronically.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Personal Information</h4>
                <p>
                  Your personal information is governed by our Privacy Policy,
                  which explains how we collect, use, and protect your data.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Market Makers</h4>
                <p>
                  We do not engage in market making or influence the price of
                  tokens. You acknowledge that the value of tokens is subject to
                  market conditions.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Disclaimer and Risks of Use of Platform</h4>
                <p>
                  Using our Platform involves risks, including the potential
                  loss of your investment. We do not guarantee the value of any
                  tokens and are not responsible for any financial loss.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Release / Indemnify</h4>
                <p>
                  You agree to release, indemnify, and hold harmless Trendx,
                  subsidiaries, affiliates, partners, independent contractors
                  from any claims, liabilities, damages, losses, or expenses
                  arising from your use of the Platform or your breach of these
                  Terms.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Termination or Suspension in Part or in Whole</h4>
                <p>
                  We may terminate or suspend your account and access to our
                  Platform, in whole or in part, immediately and without notice
                  if you breach these Terms or for any other reason at our
                  discretion.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>General</h4>
                <p>
                  These Terms constitute the entire agreement between you and
                  Trendx regarding your use of the Platform. If any provision of
                  these Terms is found to be invalid or unenforceable, the
                  remaining provisions will remain in full force and effect.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Governing Law and Dispute Resolution</h4>
                <p>
                  These Terms shall be governed by and construed in accordance
                  with the laws of Nigeria. Any disputes arising out of or
                  relating to these Terms shall be resolved through binding
                  arbitration in Nigeria, in accordance with Arbitration Rules
                  of the International Arbitration Centre for the time being in
                  force, which rules are deemed to be incorporated by reference
                  in this clause.
                  <br /> The Tribunal shall consist of one (1) arbitrator. The
                  language of the arbitration shall be English. The seat of the
                  arbitration shall be Singapore. Any award is final and may be
                  enforced in any court of competent jurisdiction. The parties
                  shall duly and punctually perform their obligations hereunder
                  pending the issuance of the arbitral award.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Contacting Us</h4>
                <p>
                  You can contact Trendx regarding these Terms, the site, or the
                  Platform at: Trendx, 1007 N Orange St. 4th Floor, Wilmington,
                  Delaware, USA. Email: support@trendx.app
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Securities Disclaimer</h4>
                <p>
                  The tokens available on our Platform are not registered with
                  any securities regulatory authority. The use of our Platform
                  does not constitute an offer to sell or a solicitation of an
                  offer to buy securities.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>No Professional or Investment Advice</h4>
                <p>
                  Trendx does not provide any professional, financial, or
                  investment advice. Any information provided on the Platform is
                  for informational purposes only and should not be construed as
                  professional advice. You should consult with a qualified
                  professional before making any investment decisions.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <p>
                  By using our Services, you acknowledge that you have read,
                  understood, and agree to be bound by these Terms and our
                  Privacy Policy.
                </p>
              </div>
            </FadeInSection>
          </div>
        </div>

        {/* <Footer
          abtPress={() => setShowAbt(true)}
          colabPress={() => showColab(true)}
          pCastPress={() => showPodcast(true)}
        /> */}
      </div>
    </>
  );
}

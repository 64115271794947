// import React, { useEffect, useState } from "react";
// import "react-multi-carousel/lib/styles.css";
// import Carousel from "react-multi-carousel";
// import c1 from "../Image/carousel/c1.webp";
// import c2 from "../Image/carousel/c2.webp";
// import c3 from "../Image/carousel/c3.webp";
// import c4 from "../Image/carousel/c4.webp";
// import c5 from "../Image/carousel/c5.webp";
// import c6 from "../Image/carousel/c6.webp";
// import c7 from "../Image/carousel/c7.webp";
// import c8 from "../Image/carousel/c8.webp";

// interface PProps {
//   images?: any;
// }
// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 4,
//     slidesToSlide: 1, // optional, default to 1.
//     partialVisibilityGutter: 50,
//   },
//   tablet: {
//     breakpoint: { max: 1250, min: 768 },
//     items: 3,
//     slidesToSlide: 1, // optional, default to 1.
//     partialVisibilityGutter: 50,
//   },
//   mobile: {
//     breakpoint: { max: 767, min: 0 },
//     items: 1.5,  // Show 1 full image and half of the next one
//     slidesToSlide: 1,
//     partialVisibilityGutter: 10,  // This controls the width of the partial visible item
//   },
// };
// const sliderImageUrl = [c1, c2, c3, c4, c5, c6, c7, c8];
// const Slider = () => {
//   return (
//     <div className="parent">
//       <Carousel
//         responsive={responsive}
//         autoPlay={true}
//         swipeable={true}
//         draggable={false}
//         showDots={false}
//         infinite={true}
//         partialVisible={false}
//         arrows={false}
//         partialVisbile={true}
//         dotListClass="custom-dot-list-style"
//         containerClass="slideCont"
//       >
//         {sliderImageUrl.map((imageUrl, index) => {
//           return (
//             <div className="slider" key={index}>
//               <img src={imageUrl} alt="movie" />
//             </div>
//           );
//         })}
//       </Carousel>
//     </div>
//   );
// };

// export default Slider

import React from "react";
import c1 from "../Image/carousel/c1.webp";
import c2 from "../Image/carousel/c2.webp";
import c3 from "../Image/carousel/c3.webp";
import c4 from "../Image/carousel/c4.webp";
import c5 from "../Image/carousel/c5.webp";
import c6 from "../Image/carousel/c6.webp";
import c7 from "../Image/carousel/c7.webp";
import c8 from "../Image/carousel/c8.webp";


const sliderImageUrl = [c1, c2, c3, c4, c5, c6, c7, c8];

const MarqueeSlider = () => {
  return (
    <div className="marquee">
      <div className="marquee-content">
        {sliderImageUrl.map((imageUrl, index) => (
          <div className="marquee-item" key={index}>
            <img src={imageUrl} alt={`carousel-img-${index}`} />
          </div>
        ))}
        {/* Duplicate the images to create a smooth looping effect */}
        {sliderImageUrl.map((imageUrl, index) => (
          <div className="marquee-item" key={`duplicate-${index}`}>
            <img src={imageUrl} alt={`carousel-img-duplicate-${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarqueeSlider;

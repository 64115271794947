import React from "react";
import cancel2 from "../Image/cancel2.svg";
import FadeInSection from "./FadInSection";
import { text } from "stream/consumers";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { useMediaQuery } from "react-responsive";

interface PProps {
  closePop?: any;
  img1?: any;
  img2?: any;
  img3?: any;
  img4?: any;
  bText?: any;
  text1?: string;
  text2?: string;
  sliderImageUrl?: any;
}
export default function Pop1({
  closePop,
  img1,
  img2,
  img3,
  img4,
  bText,
  text1 = "StableBlocks",
  text2 = "About Us",
  sliderImageUrl,
}: PProps) {
  const isMobile = useMediaQuery({ maxWidth: 649 });
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1, // Show 1 full image and half of thenext one
      slidesToSlide: 1,
      partialVisibilityGutter: 80,
    },
  };
  return (
    <div className="popBody">
      <div className="popCard">
        <div className="pnav">
          <FadeInSection>
            <div className="logoSec">
              <p>{text1}</p>
              <h4>{text2}</h4>
            </div>
          </FadeInSection>
          <button className="closeBtn" onClick={closePop}>
            <img src={cancel2} alt="" />
          </button>
        </div>
        <FadeInSection>
          <div className="pBody">
            {bText}
            {isMobile ? (
              <div className="cCont">
                <Carousel
                  responsive={responsive}
                  autoPlay={true}
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  infinite={true}
                  partialVisible={false}
                  arrows={false}
                  partialVisbile={true}
                  dotListClass="custom-dot-list-style"
                  containerClass="con"
                  itemClass="popSlide"
                >
                  {sliderImageUrl?.map((imageUrl: any, index: any) => {
                    return (
                      <div className="slideImg" key={index}>
                        <img src={imageUrl} alt="movie" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            ) : (
              <div className="imgs">
                <img src={img1} alt="" />
                <img src={img2} alt="" />
                <img src={img3} alt="" />
                <img src={img4} alt="" />
              </div>
            )}
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

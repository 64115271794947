import React, { useState, useRef, useEffect } from "react";
import Pop1 from "../Components/Pop1";
import Pop2 from "../Components/Pop2";
import { useMediaQuery } from "react-responsive";
import imgb1 from "../Image/abImg1.svg";
import imgb2 from "../Image/abImg2.svg";
import imgb3 from "../Image/abImg3.svg";
import imgb4 from "../Image/abImg4.svg";
import aImg from "../Image/analyticImg.png";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import FadeInSection from "../Components/FadInSection";
export default function PrivacyandPolicies() {
  const [showAbt, setShowAbt] = useState(false);
  const isTab = useMediaQuery({ maxWidth: 1250, minWidth: 767 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [colab, showColab] = useState(false);
  const [podcast, showPodcast] = useState(false);
  const sliderUrl3 = [imgb1, imgb2, imgb3, imgb4];
  const AppF = useRef(null);
  const BrandV = useRef(null);
  const Faq = useRef(null);
  const scrollToSection = (elementRef: any) => {
    if (elementRef.current) {
      window.scrollTo({
        top: elementRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="body">
        {showAbt && (
          <Pop1
            sliderImageUrl={sliderUrl3}
            closePop={() => setShowAbt(false)}
            bText={
              <p>
                At StableBlocks, we believe that investing should be more than
                just about returns —
                <span>it’s about building the lifestyle you want.</span>
                {isMobile ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : isTab ? (
                  <>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}{" "}
                We connect you with promising projects, talents, unique brand
                vouchers, and tools to help you make informed decisions. Whether
                you're investing for returns or reinvesting in your own
                experiences, StableBlocks is here for the entire journey.
              </p>
            }
            img1={imgb1}
            img2={imgb2}
            img3={imgb3}
            img4={imgb4}
          />
        )}
        {colab && (
          <>
            <Pop2
              text1="App feature"
              text2="Collaborate With Us"
              closePop={() => showColab(false)}
              bText={<p>Text Text</p>}
              img1={aImg}
            />
          </>
        )}
        {podcast && (
          <>
            <Pop2
              text1="App feature"
              text2="Our Podcast"
              closePop={() => showPodcast(false)}
              bText={<p>Text Text</p>}
              img1={aImg}
            />
          </>
        )}
        <div className="tCont">
          <div className="tItemHead">
            <h4 className="tH">Privacy and Policy</h4>
          </div>
          <div className="tItemBody">
            <FadeInSection>
              <div className="item">
                <p>
                  This Privacy Policy (the "Policy") explains how Trendx.app
                  ("Trendx", "we", "us" or "our") collects, uses, and shares
                  data in connection with our website, app, and other products
                  and services (the "Services"). Your use of the Services is
                  subject to this Policy and our Terms of Service.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>High-Level Summary</h4>
                <p>
                  Trendx.app is a blockchain banking and investment platform
                  that operates (www.trendx.app) and other products and
                  services. We comply with applicable laws and regulations. We
                  do not collect and store personal data, such as first name,
                  last name, street address, date of birth, email address, or IP
                  address, in connection with your use of the Services. We
                  collect non-identifiable data, such as public on-chain data,
                  and limited off-chain data like device type, browser version,
                  etc. This is to help drive production vision, not track users.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Data We Collect</h4>
                <p>
                  We do not maintain user accounts and do not collect and store
                  personal data. When you interact with the Services, we collect
                  only: <br />
                  Publicly-available blockchain dataInformation from
                  localStorage and other tracking technologies Information from
                  other sources (e.g., service providers) Survey or usability
                  information (if you participate) Correspondence (e.g., email,
                  customer support)Biographical information (if you apply for a
                  job)
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>How We Use Data</h4>
                <p>
                  We use the data we collect in accordance with your
                  instructions, including any applicable terms in our Terms of
                  Service, and as required by law.
                  <br />
                  We may also use data for:
                  <br />
                  Providing the Services Customer support Safety and security
                  Legal compliance Aggregated data
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>How We Share Data</h4>
                <p>
                  We may share or disclose the data we collect: <br /> With
                  service providers To comply with our legal obligations For
                  safety and security reasons In the event of a business change
                  (e.g., merger, acquisition) With your consent
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Third-Party Cookies</h4>
                <p>
                  We use services provided by third parties that use tracking
                  technology such as cookies, devicelD, and localStorage. You
                  can opt out of having your online activity and device data
                  collected through these third-party services.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Third-Party Links and Sites</h4>
                <p>
                  The Services may include links to websites, platforms, and
                  other services not operated or controlled by us. Please note
                  that when you interact with these other parties, they may
                  independently collect information about you.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Security</h4>
                <p>
                  We implement and maintain reasonable administrative, physical,
                  and technical security safeguards to help protect data from
                  loss, theft, misuse, unauthorized access, disclosure,
                  alteration, and destruction.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="item">
                <h4>Age Requirements</h4>
                <p>
                  As defined by the (U.S. Children’s Privacy Protection Act, or
                  “COPPA”) from children. If you believe we have received
                  personal information about a child under the age of 18, please
                  contact us at tokenize@trendx.app .
                </p>
              </div>
            </FadeInSection>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import "../css/style.css";
import cancel from "../Image/cancel.svg";
import FadeInSection from "./FadInSection";
import ig from "../Image/ig.svg";
import In from "../Image/in.svg";
import x from "../Image/xb.svg";

interface PProps {
  onclick?: any;
  visible?: any;
  abtClick?: any;
  AppF?: any;
  BrandV?: any;
  Faq?: any;
}
export default function Menu({
  onclick,
  visible,
  abtClick,
  AppF,
  BrandV,
  Faq,
}: PProps) {
  return (
    <div className={`menuBody ${visible ? "opacity" : ""}`}>
      <button className="cBtn" onClick={onclick}>
        <img src={cancel} alt="" />
      </button>

      <div className="menuWrap">
        <h4 className="links" onClick={abtClick}>
          About us
        </h4>
        <h4 className="links" onClick={() => AppF()}>
          App features
        </h4>
        <h4 className="links" onClick={() => BrandV()}>
          Brand vouchers
        </h4>
        <h4 className="links" onClick={Faq}>
          FAQs
        </h4>
        <div className="social socials">
          <a href="https://www.instagram.com/trendx.space/" target="_blank">
            <img src={ig} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/company/trendx/mycompany/"
            target="_blank"
          >
            <img src={In} alt="" />
          </a>
          <a
            href="https://twitter.com/trendxapp?t=Uuzys2o8udzE7tgTwGIYqQ&s=09"
            target="_blank"
          >
            <img src={x} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import cancel2 from "../Image/cancel2.svg";
import FadeInSection from "./FadInSection";
import { text } from "stream/consumers";

interface PProps {
  closePop?: any;
  img1?: any;
  img2?: any;
  img3?: any;
  img4?: any;
  bText?: any;
  text1?: string;
  text2?: string;
  extraClass?:any
}
export default function Pop2({
  closePop,
  img1,
  img2,
  img3,
  img4,
  bText,
  text1 = "StableBlocks",
  text2 = "About Us",
  extraClass,
}: PProps) {
  return (
    <div className="popBody">
      <div className="popCard">
        <div className="pnav">
          <FadeInSection>
            <div className="logoSec">
              <p>{text1}</p>
              <h4>{text2}</h4>
            </div>
          </FadeInSection>
          <button className="closeBtn" onClick={closePop}>
            <img src={cancel2} alt="" />
          </button>
        </div>
        <FadeInSection>
          <div className={`p2Body ${extraClass}`}>
            <div className="textCont">{bText}</div>
            <div className="imgCont">
              <img src={img1} alt="" />
            </div>
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}
